<template>
  <cks-combined-route @active="refreshActive">
    <div class="operate-list">
      <div class="w content">
        <div class="item curr" v-for="item in list" :key="item.id" @click="detail(item.id)">
          <div class="img-box">
            <img :src="item.picture" alt="">
          </div>
          <div class="title">佛山接地佛佛山接地佛教佛山接地佛教佛山接地佛教佛山接地佛教佛山接地佛教佛山接地佛教佛山接地佛教佛山接地佛教教</div>
        </div>
        <div class="item" :class="[list.length % 3 === 0 ? 'none' : 'opacity']" v-for="item in (3 - (list.length % 3))" :key="item"></div>
        <div class="back-box">
          <div class="back curr" @click="back">
            <img src="@/assets/images/news-image/back.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </cks-combined-route>
</template>

<script>
import apis from '@/request/apis';
import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';

export default {
  props: ['city'],
  setup(props) {
    const router = useRouter();
    const data = reactive({
      list: [],
      total: 0,
    });
    const getPage = async () => {
      const res = await apis.operate.getPage({
        current: 1,
        size: 100,
        conditions: JSON.stringify([{ name: 'city', opr: 'eq', value: props.city }]),
      });
      const { records, total } = res;
      data.list = records;
      data.total = total;
    };
    getPage();

    const back = () => {
      router.back();
    };
    const detail = (id) => {
      router.push({
        name: 'operateListDetail',
        params: {
          id,
        },
      });
    };
    return {
      ...toRefs(data),
      back,
      detail,
    };
  },
};
</script>

<style lang="scss" scoped>
.operate-list {
  min-height: 100vh;
  padding-top: 6.25vw;
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 32%;
      height: 15vw;
      margin-bottom: 1.563vw;
      .img-box {
        width: 100%;
        height: 12vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        height: 3vw;
        line-height: 2vw;
        padding: 0.521vw;
        font-size: 0.833vw;
        overflow: hidden;
        background-color: var(--baseColor1);
        color: #fff;
      }
    }
    .none {
      display: none;
    }
    .opacity {
      opacity: 0;
    }
    .back-box {
      width: 100%;
    }
    .back {
      float: right;
      width: 5.625vw;
      height: 2.5vw;
      margin-top: 2.188vw;
      margin-bottom: 3.438vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
